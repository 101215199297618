export const useBlockquotes = async () => {
  const storyblokApiOptions = useStoryblokApiOptions();
  const storyblokApi = useStoryblokApi();
  const storyblokStore = useStoryblokStore();

  if (!storyblokStore.blockquotes?.length) {
    const { data } = await storyblokApi.get("cdn/stories", {
      ...storyblokApiOptions,
      starts_with: "zitate/",
      is_startpage: 0,
    });

    storyblokStore.blockquotes = data.stories;
  }

  return computed(() => storyblokStore.blockquotes);
};
